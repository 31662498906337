import { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { stringify as qsStringify } from "query-string"
import { Divider } from "@mui/material"

import type { LoginOTPRes } from "types/auth.types"
import type { UserOTPRecoverCodes, UserOTPRegisterRes } from "types/users.types"

import { LogoContainer } from "widgets/styled/containers"
import {
  useLoginMutation,
  useLoginOTPMutation,
  useLoginOAuthMutation,
  useUserOTPRegisterMutation,
  useUserOTPRecoverGenerateMutation,
} from "features/api"
import { setCredentials } from "features/store/authSlice"
import {
  DEFAULT_TARGET,
  IMAGOTYPE,
  OAUTH_PROVIDERS,
  PREVIOUS_LOCATION_KEY,
} from "helpers/utils/constants"
import { getBrand } from "helpers/utils/common"
import { buildGetErrorMessage, snackbarMutation } from "helpers/utils/mutations"
import ControlledInput from "widgets/common/ControlledInput"
import LoadingButton, { LightLoadingButton } from "widgets/common/LoadingButton"
import ControlledPasswordInput from "widgets/common/ControlledPasswordInput"
import CustomLink from "widgets/common/CustomLink"
import OTPVerifierModal from "widgets/common/otp/OTPVerifierModal"
import GoogleLogo from "images/googleLogo.svg"
import MicrosoftLogo from "images/microsoftLogo.svg"
import OTPRegisterModal from "widgets/user/OTPRegisterModal"
import { infoMessage } from "helpers/utils/snackbars"
import OTPRecoverCodesModal from "widgets/user/OTPRecoverCodesModal"

function Login() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const loginFormMethods = useForm({
    mode: "all",
    defaultValues: { username: "", password: "" },
  })
  const {
    handleSubmit: handleLoginSubmit,
    getValues: getLoginValues,
    formState: { isSubmitted: isLoginSubmitted },
  } = loginFormMethods

  const loginGoogleFormMethods = useForm({ mode: "all" })
  const loginMicrosoftFormMethods = useForm({ mode: "all" })
  const {
    handleSubmit: handleGoogleSubmit,
    formState: { isSubmitted: isGoogleSubmitted },
  } = loginGoogleFormMethods
  const {
    handleSubmit: handleMicrosoftSubmit,
    formState: { isSubmitted: isMicrosoftSubmitted },
  } = loginMicrosoftFormMethods

  const [login, { isLoading: isLoadingLogin }] = useLoginMutation()
  const [loginOTP, { isLoading: isVerifying }] = useLoginOTPMutation()
  const [loginOAuth, { isLoading: isLoadingLoginOAuth }] = useLoginOAuthMutation()
  const [otpRegister] = useUserOTPRegisterMutation()
  const [otpRecoverGenerate] = useUserOTPRecoverGenerateMutation()
  const [oAuthProvider, setOAuthProvider] = useState<
    keyof typeof OAUTH_PROVIDERS | null
  >()

  const [loginErrorMessage, setLoginErrorMessage] = useState<string | null>(null)
  const [loginOAuthErrorMessage, setLoginOAuthErrorMessage] = useState<string | null>(
    null,
  )

  const [otpCode, setOtpCode] = useState("")
  const [accessToken, setAccessToken] = useState("")
  const [openOTPVerifier, setOpenOTPVerifier] = useState(false)
  const [openOTPRegister, setOpenOTPRegister] = useState(false)
  const [otpRegisterData, setOtpRegisterData] = useState<UserOTPRegisterRes | undefined>(
    undefined,
  )
  const [openRecoverCodes, setOpenRecoverCodes] = useState(false)
  const [otpRecoverCodes, setOtpRecoverCodes] = useState<UserOTPRecoverCodes | undefined>(
    undefined,
  )
  const handleOTPVerifierClose = () => {
    setOpenOTPVerifier(false)
  }
  const handleOTPRegisterClose = () => {
    setOpenOTPRegister(false)
  }
  const handleRecoverCodesClose = () => {
    setOpenRecoverCodes(false)
  }

  const onLoginSubmit = async () => {
    const account = {
      username: getLoginValues("username"),
      password: getLoginValues("password"),
    }
    return login(account)
      .unwrap()
      .then((user) => {
        if (user.otp === "required") {
          setAccessToken(user.access_token)
          registerOTP(user.access_token)
        } else if (user.otp == "challenge") {
          // OTP verification required
          setOtpCode(user.otp_code ?? "")
          setAccessToken(user.access_token)
          setOpenOTPVerifier(true)
        } else {
          dispatch(
            setCredentials({
              user: account.username,
              token: user.access_token,
              code: user.code,
              globalRole: user.role,
            }),
          )
          const target = localStorage.getItem(PREVIOUS_LOCATION_KEY) ?? DEFAULT_TARGET
          navigate(target)
        }
      })
      .catch((error) => handleError(error, account.username))
  }
  const loginWithOAuth = (provider: keyof typeof OAUTH_PROVIDERS) => async () => {
    setOAuthProvider(provider)
    setLoginOAuthErrorMessage(null)
    const loginOAuthData = {
      provider: OAUTH_PROVIDERS[provider],
      brand: getBrand(window.location.href),
    }
    loginOAuth(loginOAuthData)
      .unwrap()
      .then((oauthData) => {
        window.location.href = oauthData.redirect_uri
      })
      .catch((error) => {
        setLoginOAuthErrorMessage(error.data.message)
      })
  }

  const handleError = useCallback(
    (error, username) => {
      const body = error.data ?? {}
      if (
        error.status === 401 &&
        (body?.code === "E_WEAK_PASS" || body?.code === "E_EXPIRED_PASS")
      ) {
        const query = qsStringify({ reason: body.code })
        navigate(`/users/${username}/modify-password?${query}`)
      }
      const message = body.key ? t(`login.${body.key}`) : t("error.GENERIC_MESSAGE")
      setLoginErrorMessage(message)
    },
    [navigate, t],
  )

  const handleOTPVerifier = useCallback(
    async (value: string) => {
      const req = { accessToken, otpValue: value }
      return snackbarMutation({
        mutation: loginOTP(req).unwrap(),
        getErrorMessage: buildGetErrorMessage(t("users.UPDATE")),
      })
        .then((user: LoginOTPRes) => {
          dispatch(
            setCredentials({
              user: getLoginValues("username"),
              token: user.access_token,
              code: user.code,
              globalRole: user.role,
              otpCode: user.otp_code,
            }),
          )
          const target = localStorage.getItem(PREVIOUS_LOCATION_KEY) ?? DEFAULT_TARGET
          navigate(target)
        })
        .catch()
    },
    [accessToken, dispatch, getLoginValues, loginOTP, navigate, t],
  )

  const registerOTP = useCallback(
    async (accessToken: string) => {
      try {
        const req = { username: getLoginValues("username"), accessToken }
        const res = await snackbarMutation({
          mutation: otpRegister(req).unwrap(),
          getErrorMessage: buildGetErrorMessage(
            t("error.ENABLING_ITEM", {
              item: t("account_settings.TWO_FA").toLowerCase(),
              count: 1,
              context: "female",
            }),
          ),
        })
        setOtpRegisterData(res)
        setOpenOTPRegister(true)
        return res
      } catch {
        // nothing
      }
    },
    [getLoginValues, otpRegister, t],
  )

  const generateRecoverCodes = useCallback(async () => {
    infoMessage(t("account_settings.GENERATING_RECOVERY_CODES"), 10000)
    const req = { username: getLoginValues("username"), accessToken }
    const mutation = otpRecoverGenerate(req).unwrap()
    await snackbarMutation({
      mutation,
      getErrorMessage: buildGetErrorMessage(
        t("error.CREATING_ITEM", {
          item: t("account_settings.RECOVERY_CODES").toLowerCase(),
        }),
      ),
      getSuccessMessage: () =>
        t("success.CREATING_ITEM", {
          item: t("account_settings.RECOVERY_CODES"),
          count: 2,
          context: "male",
        }),
    }).then((res) => {
      setOtpRecoverCodes(res.recovery_codes)
      setOpenRecoverCodes(true)
      handleOTPRegisterClose()
    })
  }, [accessToken, getLoginValues, otpRecoverGenerate, t])

  return (
    <>
      <Card
        sx={{ maxWidth: 360, minWidth: 280, width: "50%", textAlign: "center" }}
        elevation={4}
      >
        <LogoContainer disableGutters>
          <Box component="img" src={IMAGOTYPE} sx={{ width: "60%" }} />
        </LogoContainer>
        <CardContent sx={{ px: "7%" }}>
          <Typography variant="body2" component="p" align="center" sx={{ mb: 2, mt: 1 }}>
            {t("login.SIGN_IN_TO_CONTINUE")}
          </Typography>
          <form name="formLogin" onSubmit={handleLoginSubmit(onLoginSubmit)} noValidate>
            <FormProvider {...loginFormMethods}>
              <Stack spacing={2}>
                <ControlledInput
                  size="small"
                  type="email"
                  placeholder=""
                  label="Email"
                  variant="outlined"
                  name="username"
                  rules={{
                    required: t("generic.FIELD_REQUIRED"),
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: t("generic.FIELD_INVALID_EMAIL"),
                    },
                  }}
                  inputLabelProps={{ shrink: true }}
                />
                <ControlledPasswordInput
                  size="small"
                  placeholder=""
                  label={t("login.PASSWORD")}
                  variant="outlined"
                  name="password"
                  rules={{
                    required: t("generic.FIELD_REQUIRED"),
                  }}
                  inputLabelProps={{ shrink: true }}
                />
              </Stack>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                sx={{ mb: 3 }}
              >
                <Grid item>
                  <CustomLink href="/accounts/recover" underlineHover variant="body2">
                    {t("login.FORGOT_YOUR_PASSWORD")}
                  </CustomLink>
                </Grid>
              </Grid>
              {isLoginSubmitted && (
                <Typography color="error" variant="body2" component="p" align="right">
                  {loginErrorMessage}
                </Typography>
              )}
              <LoadingButton
                loading={isLoadingLogin}
                variant="contained"
                styles={{ width: "100%", mt: 1, mb: 1 }}
              >
                {t("login.LOGIN")}
              </LoadingButton>
            </FormProvider>
          </form>
          {/* Disable register
          <Typography variant="body3" align="center" component="p" sx={{ mt: 1 }}>
            Don&apos;t have an account?&nbsp;
            <Link href="register" variant="body3">
              Register Now
            </Link>
          </Typography>
          */}

          <Divider sx={{ borderColor: (theme) => theme.palette.neutral[400], my: 2 }} />

          <form
            name="formLoginGoogle"
            onSubmit={handleGoogleSubmit(loginWithOAuth("GOOGLE"))}
            noValidate
          >
            <FormProvider {...loginGoogleFormMethods}>
              <LightLoadingButton
                loading={
                  isLoadingLoginOAuth &&
                  oAuthProvider === "GOOGLE" &&
                  !loginOAuthErrorMessage
                }
                type="submit"
                variant="contained"
                styles={{ gap: "12px" }}
                icon={
                  <Box component="img" src={GoogleLogo} alt={"google"} width={"20px"} />
                }
                name="provider"
                value="google"
              >
                {t("login.LOGIN_WITH_GOOGLE")}
              </LightLoadingButton>
              {oAuthProvider === "GOOGLE" &&
                isGoogleSubmitted &&
                !!loginOAuthErrorMessage && (
                  <Typography color="error" variant="body2" component="p">
                    {loginOAuthErrorMessage}
                  </Typography>
                )}
            </FormProvider>
          </form>
          <form
            name="formLoginMicrosoft"
            onSubmit={handleMicrosoftSubmit(loginWithOAuth("MICROSOFT"))}
            noValidate
          >
            <FormProvider {...loginMicrosoftFormMethods}>
              <LightLoadingButton
                loading={
                  isLoadingLoginOAuth &&
                  oAuthProvider === "MICROSOFT" &&
                  !loginOAuthErrorMessage
                }
                type="submit"
                variant="contained"
                styles={{ gap: "12px" }}
                icon={
                  <Box
                    component="img"
                    src={MicrosoftLogo}
                    alt={"microsoft"}
                    width={"20px"}
                  />
                }
                name="provider"
                value="microsoft"
              >
                {t("login.LOGIN_WITH_MICROSOFT")}
              </LightLoadingButton>
              {oAuthProvider === "MICROSOFT" &&
                isMicrosoftSubmitted &&
                !!loginOAuthErrorMessage && (
                  <Typography color="error" variant="body2" component="p">
                    {loginOAuthErrorMessage}
                  </Typography>
                )}
            </FormProvider>
          </form>
        </CardContent>
      </Card>
      <OTPVerifierModal
        open={openOTPVerifier}
        onClose={handleOTPVerifierClose}
        otpCode={otpCode}
        onVerify={handleOTPVerifier}
        isVerifying={isVerifying}
      />
      <OTPRegisterModal
        open={openOTPRegister}
        onClose={handleOTPRegisterClose}
        data={otpRegisterData}
        onComplete={generateRecoverCodes}
        username={getLoginValues("username")}
        accessToken={accessToken}
      />
      <OTPRecoverCodesModal
        open={openRecoverCodes}
        onClose={handleRecoverCodesClose}
        username={getLoginValues("username")}
        data={otpRecoverCodes}
      />
    </>
  )
}

export default Login
