import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import { invalidateCredentials } from "features/store/authSlice"
import { PREVIOUS_LOCATION_KEY } from "helpers/utils/constants"

const Logout = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(invalidateCredentials())
    localStorage.removeItem(PREVIOUS_LOCATION_KEY)
    navigate("/accounts/login")
  }, [dispatch, navigate])

  return <></>
}
export default Logout
